.partner {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
  min-height: 101vh;
}

/* Individual fade-in animation */
@keyframes fadeInIndividual {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Group fade-in animation */
@keyframes fadeInGroup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.partnerItem {
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  padding: 20px;
  width: 80%;
  text-align: left;
  opacity: 0;  /* Start hidden for animation */
  animation: fadeInIndividual 1s ease forwards; /* Default animation */
}


.websites {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.partner a{
  color: #5883c5;
  text-decoration: none;
}


.websites a:hover {
  text-decoration: underline;
}

h1, h2 {
  color: #333;
}

h1 {
  margin-bottom: 20px;
}

h2 {
  color: #666;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.friends{
  margin-top: 50px;
}