.Projects {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 100px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-left: 50px;
  margin-right: 50px;
}

@keyframes fadeInProject {
  from {
    opacity: 0;
    transform: translateY(30px);  
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.projectItem {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  transition: transform 0.6s ease, background-color 0.6s ease; /* Maintain transition for 1s */
  background-color: white;
  max-width: 85%;
  opacity: 0;
  animation: fadeInProject 0.8s ease forwards;
  max-width: 500px;
}

.projectItem:hover {
  transform: scale(1.05) !important; /* Apply scale transformation with !important */
  background-color: #f0f0f0;
}

@keyframes fadeInProject {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;  /* Only control opacity, do not touch transform here */
  }
}

@keyframes swipeIn {
  from {
    opacity: 0;
    transform: translateX(100px);  
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}





.imageContainer {
  position: relative;
  width: 100%;
  max-width: 500px;
  min-width: 500px;
  height: 350px;
  overflow: hidden;
}

.projectImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.PhotoBy {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 0.8rem;
  /*background-color: rgba(0, 0, 0, 0.5); */
  padding: 5px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .imageContainer {
    min-width: 1px;
  }

  .projectItem {
    max-width: 85%;
  }

  .Projects {
    margin-left: 0px;
    margin-right: 0px;
  }

}

