.Container {
  background: #ffffff;
}

.Vita {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 10;
  background: #ffffff;
  margin: 0px;
  padding-top: 20px;
 
}

.Vita h1 {
  margin: 0px;
  font-size: 2em;
  text-align: center;
}

.h2 {
  background: #ffffff;

  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  /* This centers the text vertically */
  justify-content: center;
  /* This centers the text horizontally */
  z-index: 10;
  transform: translateY(-17px);
  margin: 0px;
  color: #000000
}


.scrolldownIndicator {
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 5px;
  height: 50px;
  background-color: #ffffff;
  z-index: 1;
  animation: bounce 1.5s ease-in-out infinite;
  border-radius: 10px;
}

.help{
  color: #000000;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 10;
  background: #ffffff;
  margin: 0px;
  text-align: center;
}




@media (max-width: 768px) {
  .rightContent{
    width: 40vw;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  

}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(5px);
    opacity: 0.7;
  }
}