.NewFooter {
  font-family: 'Poppins', sans-serif;
  z-index: -10;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
}

.footerNotFixed {
  position: relative;
}

.footer hr {
  margin-left: 100px;
  margin-right: 100px;
  border-color: #000000;
  border-style: solid;
  border-width: 1px;
}


.footer h1 {
  padding-top: 20px;
  margin: 0;
  text-align: center;
  color: #818181;

}

.footer a {
  color: #000000;
  text-decoration: none;
}



.footerContent {
  text-decoration: none;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  justify-content: space-around;
  align-items: top;
  color: #000000;
}


@media (max-width: 768px) {

  .footer hr {
    margin-left: 15px;
    margin-right: 15px;
  }

  .rechts{
    width: 50%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}