/* News.module.css */

/* News.module.css */

.newsContainer2 {

  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 10;
  background: #ffffff;
  padding-top: 50px;
}

.newsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Maintains space between news items */
  /* No need for additional padding here if .newsContainer2 has sufficient padding */
  z-index: 20; /* Ensures this container and its children (news items) are above the footer */
  padding-left: 20px;
  padding-right: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.newsItem {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 20;
  background-color: white;
  opacity: 0;  /* Start hidden */
  animation: fadeIn 0.8s ease forwards;  /* Animation triggers when item is rendered */
}


.image {
  position: relative; /* Needed for absolute positioning of children */
  width: 500px; /* Adjusted width */
  height: 300px; /* Set a specific height */
  overflow: hidden; /* Keeps the photoBy text within the image boundaries */
}

.newsImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PhotoBy {
  position: absolute;
  bottom: 10px; 
  right: 10px;
  color: #fff; 
  font-size: 0.8rem;
  /*background-color: rgba(0, 0, 0, 0.5);  */
  padding: 5px; 
  border-radius: 5px; 
}


.newsContent {
  padding: 15px;
  flex: 1; /* Added to fill the remaining space */
}

.newsTitle, .newsDate, .newsShortText, .newsLongText {
  margin: 0 0 10px 0; /* Adjusted margins */
}

.newsLink {
  display: inline-block;
  background-color: #ffffff;
  color: rgb(70, 66, 126);
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.showMoreButton{
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ffffff;
  color: rgb(70, 66, 126);
  border: 1px solid rgb(70, 66, 126);
  border-radius: 5px;
  cursor: pointer;
}



.readMoreButton {
  display: none; /* Default state is hidden */
  color: #0066cc;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.readMoreButton:focus {
  outline: none;
}



/* Media query for mobile devices */
@media (max-width: 768px) {
  .readMoreButton {
    display: block; /* Show the button on mobile devices */
  }

  .image{
   width: 40%;
  }
}


